import React, { lazy } from 'react';
import { URLS } from './urls';

// Lazy load components
const GridView = lazy(() => import('../components/data-grid/GridView'));
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const ReportView = lazy(() => import('../reports/office-reports/OfficeReports'));
const WarehouseOrderDetail = lazy(() => import('../warehouse/WarehouseOrderDetail'));
const ProjectDetail = lazy(() => import('../projects/detail/ProjectDetail'));
const StartDetail = lazy(() => import('../starts/detail/StartDetail'));
const Overview = lazy(() => import('../components/overview/Overview'));
const OrgUnitDetail = lazy(() => import('../organizational-units/OrgUnitDetail'));
const FieldReports = lazy(() => import('../reports/field-reports/FieldReports'));
const WrappedItemView = lazy(() => import('../projects/project-items/WrappedItemView'));

interface RouteType {
    id: string;
    name: string;
    path: string;
    component: React.ComponentType<any>;
    childRoutes?: RouteType[];
}

const routes: {[key: string]: RouteType} = {
    root: {
        id: "root",
        name: "Root",
        path: URLS.root,
        component: Dashboard,
    },
    projects: {
        id: "projects",
        name: "Projects",
        path: URLS.projects,
        component: Overview,    
    },
    projectDetail: {
        id: "project_detail",
        name: "Project Detail",
        path: URLS.projectDetail,
        component: ProjectDetail,    
    },
    proposal: {
        id: "proposalItems",
        name: "Proposal Items",
        path: URLS.proposal,
        component: WrappedItemView,
    },
    budget: {
        id: "budgetItems",
        name: "Budget Items",
        path: URLS.budget,
        component: WrappedItemView,
    },
    scheduleOfValues: {
        id: "scheduleOfValues",
        name: "Schedule of Values",
        path: URLS.scheduleOfValues,
        component: WrappedItemView,
    },
    contract: {
        id: "contractItems",
        name: "Contract Items",
        path: URLS.contract,
        component: WrappedItemView,
    },
    whMaterialPricing: {
        id: "whMaterialPricing",
        name: "Warehouse Material Pricing",
        path: URLS.warehouseMaterialPricing,
        component: GridView,
    },
    concretePricing: {
        id: "concretePricing",
        name: "Concrete Pricing",
        path: URLS.concreteMixDesignPricing,
        component: GridView,
    },
    aggregatePricing: {
        id: "aggregateQuoteList",
        name: "Aggregate Pricing List",
        path: URLS.aggregatePricing,
        component: GridView,
    },
    warehouseOrders: {
        id: "warehouseOrders",
        name: "Warehouse Orders",
        path: URLS.warehouseOrders,
        component: Overview,
    },
    warehouseOrderDetail: {
        id: "warehouseOrderDetail",
        name: "Warehouse Order Detail",
        path: URLS.warehouseOrderDetail,
        component: WarehouseOrderDetail,
    },
    starts: {
        id: "start",
        name: "Start",
        path: URLS.starts,
        component: Overview,
    },
    startDetail: {
        id: "startDetail",
        name: "Start Detail",
        path: URLS.startDetail,
        component: StartDetail,
    },
    organizationalUnitDetail: {
        id: "organizationalUnitDetail",
        name: "Organizational Units",
        path: URLS.organizationalUnits,
        component: OrgUnitDetail,
    },
    officeReports: {
        id: "reports",
        name: "Office Reports",
        path: URLS.reports,
        component: ReportView,
    },
    fieldReports: {
        id: "fieldReports",
        name: "Field Reports",
        path: URLS.fieldReports,
        component: FieldReports,
    },
}

export const routeList = [...Object.values(routes)];