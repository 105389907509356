import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import apiService from './projectService';

export interface API_Data {
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    formErrors: any; 
    message: string,
    items: any,
    currentPage: number,
    totalPages: number,
    total: number,
    selectedProject: any,
    projectIndex:number, 
    detailItems: any,
    selectedItem: any,
};

interface PROJECT_DATA {
    project_id: string,
    dataset: any
}

interface ErrorDetail {
    name: string;
    message: string;
    properties: {
        message: string;
        type: string;
        path: string;
    };
    kind: string;
    path: string;
}

interface ValidationErrorDetails {
    errors: Record<string, ErrorDetail>;
    _message: string;
    name: string;
    message: string;
}

const initialState: API_Data = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    formErrors: {}, 
    message: "",
    items: [],
    currentPage: 0,
    totalPages: 0,
    total: 0, 
    selectedProject: {},
    projectIndex: 0,
    detailItems: [],
    selectedItem: {}
};

// Get purchase orders
export const getListItems = createAsyncThunk('get/docs', async (req:any, thunkAPI: any) => {
    try {
        return await apiService.getDoc(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getDoc = createAsyncThunk('get/doc', async (req:any, thunkAPI: any) => {
    try {
        return await apiService.getDoc(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createDoc = createAsyncThunk('create/doc', async (req:any, thunkAPI: any) => {
    try {
        return await apiService.createDoc(req);
    } catch (error: any) {
        const parsedErrors:any = parseFormErrors(error); 
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue({formErrors: {...parsedErrors, error: message}});
    }
});

export const uploadDoc = createAsyncThunk('upload/doc', async (req:any, thunkAPI: any) => {
    try {
        return await apiService.uploadDoc(req);
    } catch (error: any) {
        const parsedErrors = parseFormErrors(error); // Assume you have a function to parse errors
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(error);
        return thunkAPI.rejectWithValue({formErrors: {...parsedErrors, error: message}});
    }
});

export const updateDoc = createAsyncThunk('update/doc', async (req:any, thunkAPI: any) => {
    try {
        return await apiService.updateDoc(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteDoc = createAsyncThunk('delete/doc', async (req:any, thunkAPI: any) => {
    try {
        if(req?.data) return await apiService.deleteMany(req);
        return await apiService.deleteDoc(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const parseFormErrors = (error: any) => {
    const formErrors:any = {};
    if (error) {
        const validationErrorDetails: ValidationErrorDetails = error;
        console.log(validationErrorDetails)

        for (const [field, detail] of Object.entries(validationErrorDetails.errors)) {
            const clean = field.split(".")[0]
            formErrors[clean] = detail?.message;
        }
    }
    console.log(formErrors)
    return formErrors;
};

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        resetGrids: (state) => {
            state = initialState;
        },
        setIndex: (state, action) => {
            state.projectIndex = action?.payload;
        },
        setSelectedProject: (state, action) => {
            state.selectedProject = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = {};
            state.selectedItem = action.payload;
        },
        clearFormErrors: (state) => {
            state.formErrors = {}; // Clear form errors
        },
        updateItem: (state,action) => {
            state.items = state.items?.map((item: any) => (item._id === action?.payload?._id) ? action?.payload : item);
        },
        deleteItem: (state,action) => {
            state.items = state.items?.filter((item: any) => item._id !== action?.payload?.id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDoc.pending, (state:any) => {
                state.items = [];
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(getDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.selectedItem = action.payload.data || action.payload;
                state.message = action.payload.message; 
            })
            .addCase(getDoc.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload; 
            })
            .addCase(getListItems.pending, (state:any) => {
                state.message = "";  
                state.items = [];
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;      
            })
            .addCase(getListItems.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.items = action.payload.data;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.total = action.payload.total;
                state.message = action?.payload?.message || "Data Loaded." 
            })
            .addCase(getListItems.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload; 
            })
            .addCase(createDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(createDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                //state.selectedItem = action.payload.data;
                state.message = action.payload.message;
            })
            .addCase(createDoc.rejected, (state:any, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action?.payload?.formErrors?.['error'] || "Creation failed.";
                state.formErrors = action?.payload?.formErrors;
            })
            .addCase(updateDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(updateDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                //state.items = action.payload.data;
                state.message = action?.payload?.message || "Update successful.";
            })
            .addCase(updateDoc.rejected, (state, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                const parsedErrors = parseFormErrors(action?.payload?.error);
                state.formErrors = parsedErrors;

            })
            .addCase(deleteDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(deleteDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action?.payload?.message || "Item Deleted."
            })
            .addCase(deleteDoc.rejected, (state, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                const parsedErrors = parseFormErrors(action.error);
                state.formErrors = parsedErrors;
            })
            .addCase(uploadDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(uploadDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.detailItems = action.payload.data;
                state.message = action?.payload?.message || "File upload successful.";
            })
            .addCase(uploadDoc.rejected, (state:any, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action?.payload?.formErrors?.['error'];
                state.formErrors = action?.payload?.formErrors;
            })
    } 
});

export const {resetGrids, setIndex, updateItem, deleteItem, setSelectedItem, setSelectedProject, clearFormErrors} = apiSlice.actions;
export default apiSlice.reducer;
