import React, { Suspense, lazy } from 'react';
import { routeList } from '../routes/routes';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { setNavigator } from '../../utils/navigator';
import Spinner from '../spinner/Spinner';

// Lazy load components
const NotFound = lazy(() => import('../components/NotFound'));
const PrivateRoute = lazy(() => import('../routes/PrivateRoute'));
const Login = lazy(() => import('../auth/Login'));
const PasswordReset = lazy(() => import('../auth/PasswordReset'));

const MainContent = () => {
    const navigate = useNavigate();
    setNavigator(navigate);

    return(
        <Box sx={{height: "100%", width: "100%"}}>
            <Suspense fallback={<Spinner />}>
                <Routes>
                    {routeList.map(({ component: Component, ...route }) => (
                        <Route
                            key={route.id}  
                            path={route.path}
                            element={
                                <PrivateRoute>
                                    <Component />
                                </PrivateRoute> 
                            }
                        />
                    ))}
                    <Route path="/login" element={<Login />}/>
                    <Route path="/password-reset" element={<PasswordReset />} />
                    <Route path="*" element={<NotFound />}/>
                </Routes>
            </Suspense>
        </Box>            
    );
}

export default MainContent;