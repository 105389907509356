import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {CookieStorage, AuthenticationDetails, CognitoUser, CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import appAPI from '../api/appAPI';
import authService, { RegisterUserData } from './authService';

export interface AuthState {
    session:any;
    userDetails: any;
    company: any;
    division: any;
    selectedSuite: string;
    suiteDetailsConfirmed: boolean;
    isAuthenticated: boolean;
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
};

interface ResetPasswordProps {
    username: string;
    oldPassword: string;
    newPassword: string;
  }


export type RegisterProps = {
    username: string | null,
    password: string | null,
    name: string | null,
    role: string | null
} | null

type LoginProps = {
    username: string,
    password: string
};

const initialState: AuthState = {
    session: {},
    company: {},
    division: {},
    selectedSuite: "",
    userDetails: {},
    suiteDetailsConfirmed: false,
    isAuthenticated: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
};

export const poolData:ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || "",
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || "",
    Storage: new CookieStorage({domain: process.env.REACT_APP_API_URL})
};
export const userPool = new CognitoUserPool(poolData);

// Centralize Cognito User retrieval
export const getCognitoUser = async (username:string) => {
    return new CognitoUser({ Username: username, Pool: userPool, Storage: new CookieStorage({domain: process.env.REACT_APP_API_URL})});
};

// Register user
export const register = createAsyncThunk(
    'api/users',
    async (user:RegisterUserData, { rejectWithValue }) => {
        try {
            return await authService.register(user);
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Registration failed.');
        }
    }
);

// Login User
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }:LoginProps, { rejectWithValue }) => {
        try {
            const cognitoUser = await getCognitoUser(username);
            const authenticationDetails = new AuthenticationDetails({ Username: username, Password: password });
            const session = await authService.authenticateUser(password, cognitoUser, authenticationDetails);
            return { session };
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Login failed.');
        }
    }
);

// Logout
export const logout = createAsyncThunk(
    'auth/signout',
    async (_, {getState, dispatch, rejectWithValue }) => {
        try {
            //Clear cookie
            const response = await appAPI.post('auth/signout', {});
            if (response.status !== 200) {
                throw new Error('Logout failed on server');
            }
            
            //await persistor.purge(); // Clear Redux Persisted State
            sessionStorage.clear(); // Perform client-side cleanup 

            const state:any = getState();
            const cognitoUser = await getCognitoUser(state.username);
            cognitoUser?.signOut(); // Perform client-side signout

            dispatch(reset()); // Dispatch an action to reset the application state

            return true; // Indicate successful logout
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Logout failed.');
        }
    }
);

export const currentAuthenticatedUser = createAsyncThunk(
    'auth/getAuthenticatedUser',
    async (_, { rejectWithValue }) => {
        try {
            const userDetails = await authService.getCurrentUser();
            return userDetails;
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Failed to get authenticated user.');
        }
    }
);

// Password Reset
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ username, oldPassword, newPassword }: ResetPasswordProps, { rejectWithValue }) => {
        try {
            const cognitoUser = await getCognitoUser(username);
            const authenticationDetails = new AuthenticationDetails({ Username: username, Password: oldPassword });
            const session = await authService.authenticateUser(newPassword, cognitoUser, authenticationDetails);
            await authService.changePassword(cognitoUser, session, oldPassword, newPassword);
            return 'Password reset successful.';
        } catch (error:any) {
            console.log(error)
            return rejectWithValue(error?.message || 'Password reset failed.');
        }
    }
);

// Redux Slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => state = initialState,
        setSuiteDetails: (state, action) => {
            try {
              // Set company and division from payload, defaulting to empty objects
              state.company = action.payload?.company || {};
              state.division = action.payload?.division || {};
              state.selectedSuite = action.payload?.selectedSuite;
          
              // Confirm suite details only if both company and division have keys (i.e., are non-empty)
              state.suiteDetailsConfirmed =
                Object.keys(state.company).length > 0 && Object.keys(state.division).length > 0;
          
              // Serialize and store suite details only if both values are present
              const serialized = JSON.stringify({
                suiteDetailsConfirmed: state.suiteDetailsConfirmed,
                company: state.company,
                division: state.division,
                selectedSuite: action.payload?.selectedSuite,
              });
          
              sessionStorage.setItem('suiteDetails', serialized);
            } catch (error: any) {
              console.log("Error serializing suite details:", error);
            }
        },          
        loadSuiteDetails: () => {
            const serialized = sessionStorage.getItem('suiteDetails');
            if(serialized === null){
                return undefined;
            }
            return JSON.parse(serialized);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(logout.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(logout.fulfilled, (state) => {
            state = initialState;
        })
        .addCase(login.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isAuthenticated = action.payload?.session?.isValid();
            ///state.session = action.payload;
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isAuthenticated = false;
        })
        .addCase(currentAuthenticatedUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(currentAuthenticatedUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.userDetails = action.payload;
        })
        .addCase(currentAuthenticatedUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isAuthenticated = false;
        })
        .addCase(resetPassword.pending, (state) => {
            
            state.isLoading = true;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        })
    },
});

export const { reset, setSuiteDetails, loadSuiteDetails } = authSlice.actions;
export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;
export default authSlice.reducer;